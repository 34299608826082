import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IFetchOrdersWithItems } from '@actions/index';
import Loading from '@app/common/Loading';
import MUITable, { ITableColumn } from '@app/common/Table';
import { Date, timeZone } from '@dashboard_utils/index';
import Order from '@models/Order';
import ImportOrdersForm from '../../../forms/ImportOrdersForm';

interface IProps {
  fetchOrdersWithItems: (properties: IFetchOrdersWithItems) => void;
  importOrdersFormOpen: boolean;
  intl: IntlShape;
  orders: Order[];
  loading: boolean;
  openImportOrdersForm: () => void;
}

interface IState {
  anchorEl?: HTMLElement;
  columns: ITableColumn[];
}

const messages = {
  thCreated: {
    defaultMessage: 'Created',
    id: 'dashboard.orders.table.created',
  },
  thDueDate: {
    defaultMessage: 'Due Date',
    id: 'dashboard.orders.table.duedate',
  },
  thItems: {
    defaultMessage: 'Item(s)',
    id: 'dashboard.orders.table.items',
  },
  thOrderNo: {
    defaultMessage: 'Order No',
    id: 'dashboard.orders.table.orderno',
  },
};

const customRowRender = (
  columns: boolean[],
  rowData: any,
  dataIndex: number,
  rowIndex: number
) => (
  <TableRow key={rowIndex}>
    {columns[0] && <TableCell>{rowData[0]}</TableCell>}
    {columns[1] && (
      <TableCell>
        {new Date(timeZone, rowData[1]).format('yyyy-MM-dd')}
      </TableCell>
    )}
    {columns[2] && (
      <TableCell>
        {new Date(timeZone, rowData[2]).format('yyyy-MM-dd')}
      </TableCell>
    )}
    {columns[3] && <TableCell>{rowData[3]}</TableCell>}
  </TableRow>
);

class OrdersTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { intl } = props;

    this.state = {
      columns: [
        { label: intl.formatMessage(messages.thOrderNo), name: 'orderNo' },
        { label: intl.formatMessage(messages.thCreated), name: 'created' },
        { label: intl.formatMessage(messages.thDueDate), name: 'dueDate' },
        { label: intl.formatMessage(messages.thItems), name: 'items' },
      ],
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOrderImport = this.handleOrderImport.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataShallow = this.fetchDataShallow.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('WSReconnected', this.fetchDataShallow, {
      passive: true,
    });

    this.fetchData();
  }

  public componentWillUnmount() {
    window.removeEventListener('WSReconnected', this.fetchDataShallow);
  }

  public handleClick(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  public handleClose() {
    this.setState({ anchorEl: undefined });
  }

  public handleOrderImport() {
    const { openImportOrdersForm } = this.props;

    this.setState({ anchorEl: undefined }, openImportOrdersForm);
  }

  public fetchDataShallow() {
    this.fetchData(true);
  }

  public fetchData(shallow?: boolean) {
    const { fetchOrdersWithItems } = this.props;

    fetchOrdersWithItems({ shallow });
  }

  public render() {
    const { anchorEl, columns } = this.state;
    const { importOrdersFormOpen, orders, loading } = this.props;

    return (
      <>
        <Loading loading={loading}>
          <Card>
            <CardHeader
              action={
                <>
                  <IconButton
                    aria-label="More"
                    aria-owns={anchorEl ? 'long-menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={this.handleClose}
                  >
                    <MenuItem onClick={this.handleOrderImport}>
                      <ListItemIcon>
                        <CloudUploadIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <FormattedMessage
                            id="dashboard.orders.importorders"
                            defaultMessage="Import Orders"
                          />
                        }
                      />
                    </MenuItem>
                  </Menu>
                </>
              }
              title={
                <FormattedMessage
                  id="dashboard.orders.title"
                  defaultMessage="Orders"
                />
              }
            />
            <CardContent>
              <MUITable
                columns={columns}
                customRowRender={customRowRender}
                data={orders.map((o) => ({ ...o, items: o.items.length }))}
                tableId="orders"
              />
            </CardContent>
          </Card>
        </Loading>
        {importOrdersFormOpen ? <ImportOrdersForm /> : null}
      </>
    );
  }
}

export default injectIntl(OrdersTab);
