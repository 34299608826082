import React, { PureComponent, lazy, Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ITab } from '../Menu/Menu';

const Analytics = lazy(() => import('../tabs/AnalyticsTab'));
const ZoneMetrics = lazy(
  () => import('../tabs/AnalyticsTab/ZoneMetricsProvider')
);
const Player = lazy(() => import('../tabs/PlayerTab'));
const Alerts = lazy(() => import('../tabs/AlertsTab'));
const Assets = lazy(() => import('../tabs/AssetsTab'));
const Diagram = lazy(() => import('../tabs/DiagramTab'));
const Finacials = lazy(() => import('../tabs/FinancialsTab/index'));
const Employees = lazy(() => import('../tabs/EmployeesTab'));
const Shifts = lazy(() => import('../tabs/ShiftsTab'));
const Items = lazy(() => import('../tabs/ItemsTab'));
const Live = lazy(() => import('../tabs/LiveDataTab'));
const Routing = lazy(() => import('../tabs/RoutingTab'));
const Orders = lazy(() => import('../tabs/OrdersTab'));
const Reports = lazy(() => import('../tabs/ReportsTab'));
const Rules = lazy(() => import('../tabs/RulesTab/index'));
const Sensors = lazy(() => import('../tabs/SensorsTab'));
const Settings = lazy(() => import('../tabs/SettingsTab'));
const Simulations = lazy(() => import('../tabs/SimulationsTab'));
const Tickets = lazy(() => import('../tabs/TicketsTab'));
const Tasks = lazy(() => import('../tabs/TasksTab'));
const RTLSPlanner = lazy(() => import('../tabs/RTLSPlanner'));
const Error = lazy(() => import('../../utils/ErrorPage'));
const Home = lazy(() => import('../Home'));
const AssetManagementPage = lazy(() => import('../tabs/AssetsTab/Management'));
const AccountSettingsPage = lazy(() => import('../Account/Settings'));
const TasksBoard = lazy(() => import('../tabs/TasksBoard'));

interface IProps {
  language: string;
  tabs: ITab[];
}

class Tabs extends PureComponent<IProps> {
  public render() {
    const { language, tabs } = this.props;

    return (
      <Routes>
        <Route
          path="/"
          element={<Home language={language} />}
        />
        <Route
          path="/details"
          element={<Home language={language} />}
        />
        <Route
          path="/account/settings"
          element={<AccountSettingsPage />}
        />
        <Route
          path="/account/settings/:setting"
          element={<AccountSettingsPage />}
        />
        {tabs.map((tab) => {
          if (tab.id === 'analytics') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={
                  <ZoneMetrics>
                    <Analytics section="user" />
                  </ZoneMetrics>
                }
              />
            );
          }
          if (tab.id === 'alerts') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<Alerts />}
              />
            );
          }
          if (tab.id === 'assets') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<Assets />}
              />
            );
          }
          if (tab.id === 'diagnostics') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={(
                  <ZoneMetrics>
                    <Analytics section="diagnostics" />
                  </ZoneMetrics>
                )}
              />
            );
          }
          if (tab.id === 'diagrams') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<Diagram />}
              />
            );
          }
          if (tab.id === 'financials') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<Finacials />}
              />
            );
          }
          if (tab.id === 'player') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<Player />}
              />
            );
          }
          if (tab.id === 'employees') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<Employees />}
              />
            );
          }
          if (tab.id === 'live') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<Live rawMap={false} />}
              />
            );
          }
          if (tab.id === 'rawlive') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<Live rawMap />}
              />
            );
          }
          if (tab.id === 'routing') {
            return (<Route key={tab.path} path={tab.path} element={<Routing />} />);
          }
          if (tab.id === 'reports') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<Reports />}
              />
            );
          }
          if (tab.id === 'rules') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<Rules />}
              />
            );
          }
          if (tab.id === 'sensors') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<Sensors />}
              />
            );
          }
          if (tab.id === 'items') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<Items />}
              />
            );
          }
          if (tab.id === 'orders') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<Orders />}
              />
            );
          }
          if (tab.id === 'tickets') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<Tickets />}
              />
            );
          }
          if (tab.id === 'rtlsplanner') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={<RTLSPlanner />}
              />
            );
          }
          if (tab.id === 'settings') {
            return (
              <Fragment key={tab.path}>
                <Route
                  path={tab.path}
                  element={<Settings />}
                />
                <Route
                  path="/settings/:setting"
                  element={<Settings />}
                />
                <Route
                  path="/settings/:setting/:warehouseId"
                  element={<Settings />}
                />
              </Fragment>
            );
          }
          if (tab.id === 'simulations') {
            return (
              <Route
                key={tab.path}
                path={tab.path}
                element={
                  <ZoneMetrics>
                    <Simulations />
                  </ZoneMetrics>
                }
              />
            );
          }

          return (
            <Route
              key={tab.path}
              path={tab.path}
              element={<Error />}
            />
          );
        })}
        <Route
          key="asset_management"
          path="/assets/assetid/:id/management/*"
          element={<AssetManagementPage />}
        />
        <Route
          key="shifts"
          path="/shifts/*"
          element={<Shifts />}
        />
        <Route
          key="tasks-board"
          path="/tasks-board/*"
          element={<TasksBoard />}
        />
        <Route
          key="tasks"
          path="/tasks/*"
          element={<Tasks />}
        />
      </Routes>
    );
  }
}

export default Tabs;
