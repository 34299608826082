import ImageIcon from '@mui/icons-material/Image';
import { max, min, sum } from 'lodash';
import { Point, Matrix } from 'paper';
import React, { PureComponent } from 'react';
import uuid from 'uuid/v4';

import { IMap, MapWarning } from '@actions/index';
import { transformMetersToPixels } from '@dashboard_utils/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import SensorGroupWithStatus from '@models/SensorGroupWithStatus';
import { Zones } from '@models/Zone';
import { IMapState } from '@reducers/app';
import { defaultTransformationMatrix } from '../consts';
import FloatLoading from '../../FloatLoading';
import { MapActions, MapMetricData, ILayer } from '../types';
import LiveDataPing from './LiveDataPing';
import Map from './Map';
import MapImages from './MapImages';
import Paper from './Paper';
import mapEvents, { ILoadingEvent } from './eventEmitter';

export interface IProps {
  disableColor?: boolean;
  initMap: (props: IMap) => void;
  destroyMap: (id: string) => void;

  floorplan?: IncompleteFloorplan;
  activeSensorGroup: SensorGroupWithStatus | undefined;
  warnings?: Record<string, MapWarning>;
  map: Record<string, IMapState>;
  measurementUnits: string;
  filterId?: string;
  /**
   * When we reuse the same child component with different actions or layers,
   * the map needs to be forced to reload. We cannot do this based on prop changes
   * due to active drawing - meaning that if we are drawing on the map and we reload
   * on prop change, the draw will be lost and user experience ruined.
   */
  forceMapUpdate?: boolean;
  forceActiveLayers?: string[];
  forceDisabledLayers?: string[];
  metricData?: MapMetricData;
  help?: React.ReactElement | HTMLElement | string;
  ts?: number;
  /**
   * List of actions that the map support.
   * Actions are located under Map/Actions
   */
  actions?: MapActions;
  externalLayers?: Record<string, ILayer>;
  layers?: Record<string, ILayer>;
  toggleMapHelp: (id: string, help?: React.ReactElement | string) => void;
  showLiveData?: boolean;
  showRawLiveData?: boolean;
  zoneIds: string[];
  zones: Zones;
}

interface IState {
  loading: boolean;
  eventLoading: Record<string, boolean>;
  loadError?: Error;
}

class MapContainer extends PureComponent<IProps, IState> {
  private mounted = false;

  private id: string;

  private mapImages: MapImages;

  private paper: Paper;

  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: true,
      eventLoading: {},
    };

    this.id = uuid();

    this.mapImages = new MapImages();

    this.paper = new Paper({ id: this.id, mapImages: this.mapImages });

    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleEventLoading = this.handleEventLoading.bind(this);
  }

  public componentDidMount() {
    this.mounted = true;

    window.addEventListener('resize', this.handleWindowResize, {
      passive: true,
    });

    mapEvents.on('loading', this.handleEventLoading);

    this.initMap();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { activeSensorGroup, floorplan, forceMapUpdate, zoneIds } = this.props;

    if (
      this.mounted &&
      JSON.stringify(prevProps.zoneIds) !== JSON.stringify(zoneIds) &&
      zoneIds.length
    ) {
      this.zoomZone();
    }

    if (
      this.mounted &&
      (JSON.stringify(prevProps.floorplan) !== JSON.stringify(floorplan) ||
      JSON.stringify(prevProps.activeSensorGroup) !== JSON.stringify(activeSensorGroup) ||
        prevProps.forceMapUpdate !== forceMapUpdate)
    ) {
      this.reloadMap();
    }
  }

  public componentWillUnmount() {
    const { destroyMap } = this.props;

    this.mounted = false;

    destroyMap(this.id);
    this.paper.ptool.remove();

    window.removeEventListener('resize', this.handleWindowResize);
    mapEvents.removeListener('loading', this.handleEventLoading);
  }

  public handleWindowResize() {
    this.paper.handleWindowResize();
  }

  public handleEventLoading(data: ILoadingEvent) {
    this.setState((prevState) => ({
      eventLoading: {
        ...prevState.eventLoading,
        [data.loadingId]: data.type === 'start',
      },
    }));
  }

  public initMap() {
    const { activeSensorGroup, floorplan, initMap, warnings, zoneIds } =
      this.props;

    if (!floorplan) {
      this.setState({ loadError: new Error('Floorplan not found!') });
    } else {
      this.mapImages
        .load(floorplan.image)
        .then(() => {
          if (!this.mounted) {
            return;
          }

          const activeSensorGroupId = (
            activeSensorGroup || ({} as SensorGroupWithStatus)
          ).id;

          initMap({
            id: this.id,
            floorplanId: floorplan.id,
            activeSensorGroupId,
            mapImages: this.mapImages,
            paper: this.paper,
            warnings,
          });

          if (zoneIds.length) {
            this.zoomZone();
          } else {
            this.paper.centerView(this.mapImages.width, this.mapImages.height);
          }

          this.setState({ loading: false });
        })
        .catch((error) => {
          if (!this.mounted) {
            return;
          }

          this.setState({ loading: false, loadError: error });
        });
    }
  }

  public reloadMap() {
    const { destroyMap } = this.props;

    destroyMap(this.id);
    this.initMap();
  }

  public zoomZone() {
    const { floorplan, zoneIds, zones } = this.props;

    let selectedZoneCordinates: [number, number][] = [];
    for (let z = 0; z <= zoneIds.length; z += 1) {
      const zone = zones[zoneIds[z]];
      if (zone) {
        selectedZoneCordinates = selectedZoneCordinates.concat(
          zone.coordinates
        );
      }
    }

    if (selectedZoneCordinates.length) {
      const transformedCoodinates = selectedZoneCordinates.map((c) =>
        transformMetersToPixels(
          c,
          (floorplan || {}).transformationMatrix || defaultTransformationMatrix,
          (floorplan || {}).scale || 1
        )
      );
      const xs = transformedCoodinates.map((c) => c[0]);
      const xy = transformedCoodinates.map((c) => c[1]);

      const center = new Point(
        sum(xs) / transformedCoodinates.length,
        sum(xy) / transformedCoodinates.length
      );
      const tPoint = center.transform(
        new Matrix(1, 0, 0, -1, 0, this.mapImages.height)
      );

      const width = (max(xs) || 0) - (min(xs) || 0);
      const height = (max(xy) || 0) - (min(xy) || 0);

      this.paper.fitToView(tPoint.x, tPoint.y, { width, height });
    }
  }

  public render() {
    const {
      actions,
      disableColor,
      floorplan,
      filterId,
      forceActiveLayers,
      forceDisabledLayers,
      metricData,
      help,
      externalLayers,
      layers,
      map,
      measurementUnits,
      toggleMapHelp,
      ts,
      showLiveData,
      showRawLiveData,
    } = this.props;
    const { eventLoading, loading, loadError } = this.state;

    if (!map[this.id]) {
      return null;
    }

    const isEventLoading = !!Object.keys(eventLoading).find(
      (k) => eventLoading[k]
    );

    return (
      <>
        <FloatLoading loading={loading || isEventLoading}>
          {loadError ? (
            <div className="dashboard-map-loading">
              <ImageIcon color="disabled" fontSize="large" />
            </div>
          ) : (
            <>
              <Map
                disableColor={disableColor}
                id={this.id}
                forceActiveLayers={forceActiveLayers}
                forceDisabledLayers={forceDisabledLayers}
                metricData={metricData}
                filterId={filterId}
                paper={this.paper}
                mapImages={this.mapImages}
                actions={actions}
                floorplan={floorplan!}
                externalLayers={externalLayers}
                layers={layers}
                measurementUnits={measurementUnits}
                help={help}
                showLiveData={showLiveData}
                showRawLiveData={showRawLiveData}
                toggleMapHelp={toggleMapHelp}
                ts={ts}
              />
              {showLiveData || showRawLiveData ? (
                <LiveDataPing id={this.id} />
              ) : null}
            </>
          )}
        </FloatLoading>
      </>
    );
  }
}

export default MapContainer;
