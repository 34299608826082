import Avatar from '@mui/material/Avatar';
import React, { FunctionComponent } from 'react';

import Account from '@models/Account';

interface IProps {
  account: Account;
  size?: number;
}

const AvatarComponent: FunctionComponent<IProps> = ({
  account,
  size,
}: IProps) => {
  const initials = account.name
    .split(' ')
    .map((n) => n[0])
    .join('');

  return (
    <Avatar
      style={{
        backgroundColor: account.image ? '#efefef' : undefined,
        height: size ? `${size}px` : undefined,
        width: size ? `${size}px` : undefined,
      }}
      src={account.image}
    >
      {!account.image ? initials : null}
    </Avatar>
  );
};
AvatarComponent.defaultProps = {
  size: undefined,
};

export default AvatarComponent;
