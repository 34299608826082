import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LayersIcon from '@mui/icons-material/Layers';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { Point } from 'paper';
import React, { PureComponent } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import ReactResizeDetector from 'react-resize-detector';

import { withRouter } from '@app/utils/withRouter';
import { Date } from '@dashboard_utils/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from '../../../utils/localStorageUtils';
import { getOffsetLeft, getOffsetTop } from '../../../utils/mapUtils';
import { renderLayerToggle } from '../consts';
import { ILayer, MapActions, MapMetricData } from '../types';
import Help from './Help';
import Hover from './Hover';
import MapLayerHelp from './MapLayerHelp';
import LayerScale from './LayerScale';
import MapWarnings from '../MapWarnings';
import messages from '../messages';
import Actions from './Actions';
import Layers from './Layers';
import FeatureInfo from './FeatureInfo';
import MapImages from './MapImages';
import Paper, { ZOOM_FACTOR } from './Paper';
import mapEvents from './eventEmitter';

const version = process.env.REACT_APP_VERSION;

const layerMessages = defineMessages({
  boundariesObstaclesLayer: {
    defaultMessage: 'Boundaries, Obstacles & Landmarks',
    id: 'dashboard.datamap.boundariesobstacles',
  },
  floorplan: {
    defaultMessage: 'Floor plan',
    id: 'dashboard.datamap.floorplan',
  },
  assets: {
    defaultMessage: 'Assets',
    id: 'dashboard.livemap.layers.forklifts',
  },
  racks: {
    defaultMessage: 'Racks',
    id: 'dashboard.livemap.layers.racks',
  },
  roadmap: {
    defaultMessage: 'Street Map',
    id: 'dashboard.livemap.layers.streetmap',
  },
  satellite: {
    defaultMessage: 'Satellite',
    id: 'dashboard.livemap.layers.satellite',
  },
  gmaps: {
    defaultMessage: 'World Map',
    id: 'dashboard.livemap.layers.gmaps',
  },
  threeD: {
    defaultMessage: '3D Map',
    id: 'dashboard.livemap.layers.threed',
  },
  sensors: {
    defaultMessage: 'Sensors',
    id: 'dashboard.livemap.layers.sensors',
  },
  zones: {
    defaultMessage: 'Zones',
    id: 'dashboard.livemap.layers.zones',
  },
  itemLocations: {
    defaultMessage: 'Item Locations',
    id: 'dashboard.livemap.layers.itemlocations',
  },
});

/*
  To prevent offset due to overlaying position fixed and absolute elements 
  disableMouseEvents?: boolean;
*/
export interface IProps {
  disableColor?: boolean;

  id: string;
  filterId?: string;
  forceActiveLayers?: string[];
  forceDisabledLayers?: string[];
  metricData?: MapMetricData;

  paper: Paper;
  mapImages: MapImages;

  floorplan: IncompleteFloorplan;

  actions?: MapActions;
  externalLayers?: Record<string, ILayer>;
  layers?: Record<string, ILayer>;
  measurementUnits?: string;

  help?: React.ReactElement | HTMLElement | string;
  toggleMapHelp: (id: string, help?: React.ReactElement | string) => void;

  intl: IntlShape;

  ts?: number;

  router: any;

  showLiveData?: boolean;
  showRawLiveData?: boolean;
}

interface IState {
  anchorEl?: HTMLElement;
  externalAnchorEl?: HTMLElement;
  color: boolean;
  ready: boolean;
  innerLayers: Record<string, ILayer>;
}

class Map extends PureComponent<IProps, IState> {
  private isLocked = false;

  private isDragging = false;

  private isScaling = false;

  private interval!: any;

  private canvas!: HTMLCanvasElement;

  constructor(props: IProps) {
    super(props);

    this.state = {
      color: getFromLocalStorage(`map_colorize_${version}`) === 'enabled',
      innerLayers: {},
      ready: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setCanvasEvents = this.setCanvasEvents.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.fitView = this.fitView.bind(this);
    this.showHelp = this.showHelp.bind(this);
    this.toggleColor = this.toggleColor.bind(this);
    this.toggleLayer = this.toggleLayer.bind(this);
    this.updateScale = this.updateScale.bind(this);
    this.handleLock = this.handleLock.bind(this);
  }

  public componentDidMount() {
    const { id, paper } = this.props;
    this.canvas = document.getElementById(`${id}_map`) as HTMLCanvasElement;
    paper.setup(this.canvas);

    this.setLayersState().then(() => {
      this.setCanvasEvents();

      this.fitView();

      this.interval = setInterval(() => {
        const { ts } = this.props;

        mapEvents.emit('clean', ts || Date.now() - 10000);
      }, 2000);
    });

    mapEvents.on('lockmap', this.handleLock);
  }

  public componentDidUpdate(prevProps: any) {
    const { forceDisabledLayers } = this.props;

    if (
      JSON.stringify(forceDisabledLayers || []) !==
      JSON.stringify(prevProps.forceDisabledLayers || [])
    ) {
      this.updateForcedLayers();
    }
  }

  public componentWillUnmount() {
    const { paper } = this.props;

    // Clear scope so that features don't render on the old scope on component re-render
    // https://stackoverflow.com/questions/39456324/how-to-properly-destroy-paperScope-js-scope

    mapEvents.removeListener('lockmap', this.handleLock);

    paper.scope.project.clear();
    clearInterval(this.interval);
  }

  public handleLock(event: string) {
    if (event === 'lock') {
      this.isLocked = true;

      return;
    }

    this.isLocked = false;
  }

  public handleClick(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  public handleClose() {
    this.setState({ anchorEl: undefined });
  }

  public setLayersState() {
    const {
      filterId,
      forceActiveLayers,
      forceDisabledLayers,
      intl,
      showLiveData,
      showRawLiveData,
      layers,
    } = this.props;

    return new Promise((resolve) => {
      const isZonesForced = (forceActiveLayers || []).indexOf('zones') !== -1;

      const gmapsActive =
        (forceDisabledLayers || []).indexOf('gmaps') === -1 &&
        (getFromLocalStorage(`map_layers_gmaps_${filterId}`) || 'false') !==
          'false';

      const defaultLayers: Record<string, ILayer> = {
        boundaries: {
          id: 'boundaries',
          active:
            (forceDisabledLayers || []).indexOf('boundaries') === -1 &&
            (getFromLocalStorage(`map_layers_boundaries_${filterId}`) || '') !==
              'false',
          disabled:
            (forceDisabledLayers || []).indexOf('boundaries') !== -1 ||
            gmapsActive,
          label: intl.formatMessage(layerMessages.boundariesObstaclesLayer),
          primary: false,
          secondary: true,
        },
        floorplan: {
          id: 'floorplan',
          active:
            (forceDisabledLayers || []).indexOf('floorplan') === -1 &&
            (getFromLocalStorage(`map_layers_floorplan_${filterId}`) || '') !==
              'false',
          disabled:
            (forceDisabledLayers || []).indexOf('floorplan') !== -1 ||
            gmapsActive,
          label: intl.formatMessage(layerMessages.floorplan),
          primary: true,
          secondary: false,
        },
        assets: {
          id: 'assets',
          active:
            (forceDisabledLayers || []).indexOf('assets') === -1 &&
            !isZonesForced
              ? (getFromLocalStorage(`map_layers_assets_${filterId}`) || '') !==
                'false'
              : false,
          disabled:
            (forceDisabledLayers || []).indexOf('assets') !== -1 ||
            isZonesForced ||
            gmapsActive,
          label: intl.formatMessage(layerMessages.assets),
          primary: true,
          secondary: false,
          styleClass: 'forklift',
          showLiveData,
          showRawLiveData,
        },
        racks: {
          id: 'racks',
          active:
            (forceDisabledLayers || []).indexOf('racks') === -1 &&
            (getFromLocalStorage(`map_layers_racks_${filterId}`) || '') !==
              'false',
          disabled:
            (forceDisabledLayers || []).indexOf('racks') !== -1 || gmapsActive,
          label: intl.formatMessage(layerMessages.racks),
          primary: true,
          secondary: false,
        },
        roadmap: {
          id: 'roadmap',
          active:
            (forceDisabledLayers || []).indexOf('roadmap') === -1 &&
            (getFromLocalStorage(`map_layers_roadmap_${filterId}`) ||
              'false') !== 'false',
          disabled: (forceDisabledLayers || []).indexOf('roadmap') !== -1,
          label: intl.formatMessage(layerMessages.roadmap),
          primary: true,
          secondary: false,
        },
        satellite: {
          id: 'satellite',
          active:
            (forceDisabledLayers || []).indexOf('satellite') === -1 &&
            (getFromLocalStorage(`map_layers_satellite_${filterId}`) ||
              'false') !== 'false',
          disabled: (forceDisabledLayers || []).indexOf('satellite') !== -1,
          label: intl.formatMessage(layerMessages.satellite),
          primary: true,
          secondary: false,
        },
        gmaps: {
          id: 'gmaps',
          active: gmapsActive,
          disabled: (forceDisabledLayers || []).indexOf('gmaps') !== -1,
          label: intl.formatMessage(layerMessages.gmaps),
          primary: true,
          secondary: false,
        },
        threed: {
          id: 'threed',
          active:
            (forceDisabledLayers || []).indexOf('threed') === -1 &&
            (getFromLocalStorage(`map_layers_threed_${filterId}`) ||
              'false') !== 'false',
          disabled: (forceDisabledLayers || []).indexOf('threed') !== -1,
          label: intl.formatMessage(layerMessages.threeD),
          primary: true,
          secondary: false,
        },
        sensors: {
          id: 'sensors',
          active:
            (forceDisabledLayers || []).indexOf('sensors') === -1 &&
            (getFromLocalStorage(`map_layers_sensors_${filterId}`) || '') !==
              'false',
          disabled:
            (forceDisabledLayers || []).indexOf('sensors') !== -1 ||
            gmapsActive,
          label: intl.formatMessage(layerMessages.sensors),
          primary: true,
          secondary: false,
          styleClass: 'stationary-beacon',
        },
        zones: {
          id: 'zones',
          active:
            (forceDisabledLayers || []).indexOf('zones') === -1 && isZonesForced
              ? true
              : (forceDisabledLayers || []).indexOf('zones') === -1 &&
                (getFromLocalStorage(`map_layers_zones_${filterId}`) || '') !==
                  'false',
          disabled:
            (forceDisabledLayers || []).indexOf('zones') !== -1 ||
            isZonesForced ||
            gmapsActive,
          label: intl.formatMessage(layerMessages.zones),
          primary: isZonesForced,
          secondary: !isZonesForced,
        },
        itemLocations: {
          id: 'itemLocations',
          active:
            (forceDisabledLayers || []).indexOf('itemLocations') === -1 &&
            (getFromLocalStorage(`map_layers_itemLocations_${filterId}`) || '') !==
              'false',
          disabled:
            (forceDisabledLayers || []).indexOf('itemLocations') !== -1 ||
            gmapsActive,
          label: intl.formatMessage(layerMessages.itemLocations),
          primary: true,
          secondary: false,
        },
      };

      this.setState({ innerLayers: layers || defaultLayers, ready: true }, () =>
        resolve(undefined)
      );
    });
  }

  public setCanvasEvents() {
    let start = new Point(0, 0);
    let touchDistance = 0;

    const dragStart = (e: MouseEvent | TouchEvent | any) => {
      if (this.isLocked) {
        return;
      }

      this.isDragging = true;

      if (typeof TouchEvent !== 'undefined' && e instanceof TouchEvent) {
        if (e.targetTouches.length > 1) {
          this.isDragging = false;
          this.isScaling = true;

          const distanceX = e.targetTouches[0].pageX - e.targetTouches[1].pageX;
          const distanceY = e.targetTouches[0].pageY - e.targetTouches[1].pageY;

          touchDistance = Math.sqrt(
            distanceX * distanceX + distanceY * distanceY
          );

          return;
        }

        start = new Point(
          e.targetTouches[0].pageX - getOffsetLeft(this.canvas),
          e.targetTouches[0].pageY - getOffsetTop(this.canvas)
        );

        return;
      }

      start = new Point(
        (e.offsetX || e.pageX) - getOffsetLeft(this.canvas),
        (e.offsetY || e.pageY) - getOffsetTop(this.canvas)
      );
    };

    const dragCancel = () => {
      this.isDragging = false;
      this.isScaling = false;
    };

    const dragEnd = (e: MouseEvent | TouchEvent | any) => {
      const { paper } = this.props;

      if (this.isDragging) {
        let end;

        if (typeof TouchEvent !== 'undefined' && e instanceof TouchEvent) {
          end = new Point(
            e.targetTouches[0].pageX - getOffsetLeft(this.canvas),
            e.targetTouches[0].pageY - getOffsetTop(this.canvas)
          );
        } else {
          end = new Point(
            (e.offsetX || e.pageX) - getOffsetLeft(this.canvas),
            (e.offsetY || e.pageY) - getOffsetTop(this.canvas)
          );
        }

        paper.scope.view.center = paper.scope.view.viewToProject(
          paper.scope.view
            .projectToView(paper.scope.view.center || ({} as paper.Point))
            .subtract(end.subtract(start))
        );

        start = end;

        return;
      }

      if (
        this.isScaling &&
        typeof TouchEvent !== 'undefined' &&
        e instanceof TouchEvent &&
        e.targetTouches.length > 1
      ) {
        const mousePosition = new Point(
          (e.targetTouches[0].pageX + e.targetTouches[1].pageX) / 2 -
            getOffsetLeft(this.canvas),
          (e.targetTouches[0].pageY + e.targetTouches[1].pageY) / 2 -
            getOffsetTop(this.canvas)
        );
        const viewPosition = paper.scope.view.viewToProject(mousePosition);

        let newZoom = paper.getZoom();

        const distanceX = e.targetTouches[0].pageX - e.targetTouches[1].pageX;
        const distanceY = e.targetTouches[0].pageY - e.targetTouches[1].pageY;

        const currentTouchDistance = Math.sqrt(
          distanceX * distanceX + distanceY * distanceY
        );

        if (touchDistance <= currentTouchDistance) {
          newZoom = paper.getZoom() * ZOOM_FACTOR;
        } else {
          newZoom = paper.getZoom() / ZOOM_FACTOR;
        }

        const oldZoom = paper.getZoom();
        const betaZoom = oldZoom / newZoom;

        const positionCenter = viewPosition.subtract(
          paper.scope.view.center || ({} as paper.Point)
        );
        const offset = viewPosition
          .subtract(positionCenter.multiply(betaZoom))
          .subtract(paper.scope.view.center || ({} as paper.Point));

        paper.scope.view.center = paper.scope.view.center!.add(offset);
        paper.setZoom(newZoom);

        touchDistance = currentTouchDistance;

        this.updateScale();
      }
    };

    if (this.canvas) {
      this.canvas.ontouchstart = dragStart;
      this.canvas.onmousedown = dragStart;

      this.canvas.onmouseup = dragCancel;
      this.canvas.onmouseleave = dragCancel;
      this.canvas.ontouchend = dragCancel;
      this.canvas.ontouchcancel = dragCancel;

      this.canvas.onmousemove = dragEnd;
      this.canvas.ontouchmove = dragEnd;

      // Prevent right-click popup
      this.canvas.oncontextmenu = (e: MouseEvent) => {
        e.preventDefault();
      };
      this.canvas.ondblclick = (e: MouseEvent) => {
        const { paper } = this.props;

        if (this.isDragging === true) {
          return;
        }
        const mousePosition = new Point(
          (e.offsetX || e.pageX) - getOffsetLeft(this.canvas),
          (e.offsetY || e.pageY) - getOffsetTop(this.canvas)
        );
        const viewPosition = paper.scope.view.viewToProject(mousePosition);

        const newZoom = paper.getZoom() * ZOOM_FACTOR * 1.5;
        const oldZoom = paper.getZoom();
        const betaZoom = oldZoom / newZoom;

        const positionCenter = viewPosition.subtract(
          paper.scope.view.center || ({} as paper.Point)
        );
        const offset = viewPosition
          .subtract(positionCenter.multiply(betaZoom))
          .subtract(paper.scope.view.center || ({} as paper.Point));

        paper.scope.view.center = paper.scope.view.center!.add(offset);
        paper.setZoom(newZoom);

        this.updateScale();
      };

      let wheelTimeout: any;
      this.canvas.addEventListener(
        'wheel',
        (e: WheelEvent) => {
          const { paper } = this.props;

          if (this.isDragging === true) {
            return;
          }

          const mousePosition = new Point(
            (e.offsetX || e.pageX) - getOffsetLeft(this.canvas),
            (e.offsetY || e.pageY) - getOffsetTop(this.canvas)
          );
          const viewPosition = paper.scope.view.viewToProject(mousePosition);

          let newZoom = paper.getZoom();
          if (e.deltaX < 0 || e.deltaY < 0) {
            newZoom = paper.getZoom() * ZOOM_FACTOR;
          } else {
            newZoom = paper.getZoom() / ZOOM_FACTOR;
          }
          const oldZoom = paper.getZoom();
          const betaZoom = oldZoom / newZoom;

          const positionCenter = viewPosition.subtract(
            paper.scope.view.center || ({} as paper.Point)
          );
          const offset = viewPosition
            .subtract(positionCenter.multiply(betaZoom))
            .subtract(paper.scope.view.center || ({} as paper.Point));

          paper.scope.view.center = paper.scope.view.center!.add(offset);
          paper.setZoom(newZoom);

          if (wheelTimeout) {
            clearTimeout(wheelTimeout);
          }

          this.updateScale();
        },
        { passive: true }
      );
    }

    return null;
  }

  /**
   * @description Updates map scale from current zoom and floorplan scale
   */
  private updateScale() {
    const { floorplan, id, paper, measurementUnits } = this.props;

    const closestInteger = Math.ceil(
      80 /
        (((floorplan.scale || 1) /
          (measurementUnits === 'si' ? 1 : 3.2808399)) *
          paper.getZoom())
    );

    const scaleElement: HTMLElement | null = document.getElementById(
      `${id}_scale`
    );

    if (scaleElement) {
      const child = scaleElement.children[0] as HTMLDivElement;

      child.textContent = `${closestInteger} ${
        measurementUnits === 'si' ? 'm' : 'ft'
      }`;
      child.style.width = `${
        closestInteger *
        ((floorplan.scale || 1) / (measurementUnits === 'si' ? 1 : 3.2808399)) *
        paper.getZoom()
      }px`;
    }

    mapEvents.emit('resized');
  }

  private zoomIn() {
    const { paper } = this.props;

    paper.zoomIn();

    return this.updateScale();
  }

  private zoomOut() {
    const { paper } = this.props;

    paper.zoomOut();

    return this.updateScale();
  }

  private fitView() {
    const { paper, mapImages } = this.props;

    paper.fitToView(mapImages.width / 2, mapImages.height / 2);

    this.updateScale();
  }

  /**
   * @description Activates or deactivates floorplan image color
   * @param       {ChangeEvent} e
   */
  public toggleColor() {
    this.setState((currentState) => {
      saveToLocalStorage(
        `map_colorize_${version}`,
        !currentState.color ? 'enabled' : 'disable'
      );

      return { color: !currentState.color };
    });
  }

  private showHelp() {
    const { id, intl, help, toggleMapHelp } = this.props;

    toggleMapHelp(
      id,
      <>
        {help || null}
        <div>{intl.formatMessage(messages.help)}</div>
      </>
    );
  }

  public toggleLayer(id: string) {
    const { filterId } = this.props;
    const { innerLayers } = this.state;

    const active = !innerLayers[id].active;

    if (id === 'satellite' && active) {
      saveToLocalStorage(`map_layers_roadmap_${filterId}`, 'false');
      saveToLocalStorage(`map_layers_gmaps_${filterId}`, 'false');
      saveToLocalStorage(`map_layers_threed_${filterId}`, 'false');
    } else if (id === 'roadmap' && active) {
      saveToLocalStorage(`map_layers_satellite_${filterId}`, 'false');
      saveToLocalStorage(`map_layers_gmaps_${filterId}`, 'false');
      saveToLocalStorage(`map_layers_threed_${filterId}`, 'false');
    } else if (id === 'gmaps' && active) {
      saveToLocalStorage(`map_layers_satellite_${filterId}`, 'false');
      saveToLocalStorage(`map_layers_roadmap_${filterId}`, 'false');
      saveToLocalStorage(`map_layers_threed_${filterId}`, 'false');
    } else if (id === 'threed' && active) {
      saveToLocalStorage(`map_layers_satellite_${filterId}`, 'false');
      saveToLocalStorage(`map_layers_roadmap_${filterId}`, 'false');
      saveToLocalStorage(`map_layers_gmaps_${filterId}`, 'false');
    }

    saveToLocalStorage(`map_layers_${id}_${filterId}`, String(active));

    this.setLayersState();
  }

  public updateForcedLayers() {
    const { forceDisabledLayers, forceActiveLayers } = this.props;
    const { innerLayers } = this.state;

    const isZonesForced = (forceActiveLayers || []).indexOf('zones') !== -1;

    Object.keys(innerLayers).forEach((layerId) => {
      const disabled =
        (forceDisabledLayers || []).indexOf(layerId) !== -1 ||
        ((layerId === 'assets' || layerId === 'zones') && isZonesForced);
      innerLayers[layerId].active = disabled
        ? !disabled
        : innerLayers[layerId].active;
      innerLayers[layerId].disabled = disabled;
    });

    this.setState({ innerLayers });
  }

  public render() {
    const {
      actions,
      disableColor,
      externalLayers,
      filterId,
      id,
      intl,
      metricData,
      router,
    } = this.props;
    const { anchorEl, color, innerLayers, ready } = this.state;

    return (
      <>
        <div className="map-window">
          <div className="map-unselectable map-control">
            <IconButton
              aria-label="Download"
              disabled={
                (innerLayers.gmaps || {}).active ||
                (innerLayers.threed || {}).active
              }
              onClick={() => mapEvents.emit('download')}
            >
              <WallpaperIcon />
            </IconButton>
            <IconButton
              id="zoom-in"
              disabled={
                (innerLayers.gmaps || {}).active ||
                (innerLayers.threed || {}).active
              }
              title={intl.formatMessage(messages.zoomIn)}
              onClick={this.zoomIn}
            >
              <ZoomInIcon />
            </IconButton>
            <IconButton
              id="zoom-out"
              disabled={
                (innerLayers.gmaps || {}).active ||
                (innerLayers.threed || {}).active
              }
              title={intl.formatMessage(messages.zoomOut)}
              onClick={this.zoomOut}
            >
              <ZoomOutIcon />
            </IconButton>
            <IconButton
              id="map-fitview"
              disabled={
                (innerLayers.gmaps || {}).active ||
                (innerLayers.threed || {}).active
              }
              title={intl.formatMessage(messages.fitView)}
              onClick={this.fitView}
            >
              <ZoomOutMapIcon />
            </IconButton>
            {disableColor !== true ? (
              <IconButton
                id="map-colorize"
                disabled={(innerLayers.gmaps || {}).active}
                style={{ color: color ? '#f50057' : undefined }}
                onClick={this.toggleColor}
                title={intl.formatMessage(messages.colorize)}
              >
                <FormatColorFillIcon />
              </IconButton>
            ) : null}
            <IconButton id="map-layers" onClick={this.handleClick}>
              <LayersIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={this.handleClose}
            >
              {Object.keys(innerLayers)
                .filter((k) => innerLayers[k].secondary)
                .map((k) => (
                  <MenuItem key={`b_${k}`}>
                    {renderLayerToggle(innerLayers[k], this.toggleLayer)}
                  </MenuItem>
                ))}

              {Object.keys(innerLayers)
                .filter((k) => innerLayers[k].secondary !== true)
                .map((k, index) => (
                  <MenuItem
                    key={`c_${k}`}
                    style={{
                      marginTop: index === 0 ? '15px' : '0px',
                    }}
                  >
                    {renderLayerToggle(innerLayers[k], this.toggleLayer)}
                  </MenuItem>
                ))}
            </Menu>

            <IconButton
              id="map-help"
              onClick={this.showHelp}
              title={intl.formatMessage(messages.help)}
            >
              <HelpOutlineIcon />
            </IconButton>
            <MapLayerHelp filterId={filterId} />
          </div>
          <div className="map-canvas">
            <MapWarnings id={id} filterId={filterId} metricData={metricData} />
            <LayerScale id={id} />
            <ReactResizeDetector
              onResize={() => window.dispatchEvent(new Event('resize'))}
            />
            <canvas id={`${id}_map`} className="map" />
            <div id={`${id}_scale`} className="map-scale-line map-unselectable">
              <div className="map-scale-line-inner" />
            </div>
            <Help id={id} />
            {ready ? (
              <>
                <Hover id={id} />
                <FeatureInfo
                  id={id}
                  filterId={filterId}
                  metricData={metricData}
                />
                <Layers
                  id={id}
                  filterId={filterId}
                  color={color}
                  layers={{
                    ...(externalLayers || {}),
                    ...innerLayers,
                  }}
                  metricData={metricData}
                  router={router}
                />
                <Actions
                  id={id}
                  filterId={filterId}
                  color={color}
                  actions={actions}
                  metricData={metricData}
                />
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default injectIntl(withRouter(Map));
