import React, { FunctionComponent } from 'react';
import { IntlShape } from 'react-intl';

import { ForkliftTypes } from '@app/dashboard/tabs/FinancialsTab/Calc';
import CreateOrEditWarehouse from './CreateOrEditWarehouse';

interface IProps {
  loading: boolean;
  updateWarehouse: (
    intl: IntlShape,
    name: string,
    site: string,
    timezone: string,
    country: string,
    metricsTotalPalletsMoved?: number,
    metricsFleetUsedCapacity?: number,
    numberOfShifts?: number,
    shiftHours?: number,
    workingDays?: number,
    targetForkliftType?: keyof typeof ForkliftTypes
  ) => void;
  name: string;
  site: string;
  timezone: string;
  country: string;
  metricsTotalPalletsMoved?: number;
  metricsFleetUsedCapacity?: number;
  numberOfShifts?: number;
  shiftHours?: number;
  workingDays?: number;
  targetForkliftType?: keyof typeof ForkliftTypes;
}

const EditWarehouse: FunctionComponent<IProps> = ({
  loading,
  updateWarehouse,
  name,
  site,
  timezone,
  country,
  metricsTotalPalletsMoved,
  metricsFleetUsedCapacity,
  numberOfShifts,
  shiftHours,
  workingDays,
  targetForkliftType,
}: IProps) => (
  <CreateOrEditWarehouse
    onNext={updateWarehouse}
    name={name}
    site={site}
    timezone={timezone}
    country={country}
    metricsTotalPalletsMoved={metricsTotalPalletsMoved}
    metricsFleetUsedCapacity={metricsFleetUsedCapacity}
    numberOfShifts={numberOfShifts}
    shiftHours={shiftHours}
    workingDays={workingDays}
    targetForkliftType={targetForkliftType}
    loading={loading}
  />
);
EditWarehouse.defaultProps = {
  metricsTotalPalletsMoved: undefined,
  metricsFleetUsedCapacity: undefined,
  numberOfShifts: undefined,
  shiftHours: undefined,
  workingDays: undefined,
  targetForkliftType: undefined,
};
export default EditWarehouse;
