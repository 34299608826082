import React, { FunctionComponent } from 'react';

import CreateFloorplan from './CreateFloorplanContainer';
import EditFloorplan from './EditFloorplanContainer';

interface IProps {
  floorplanId?: string;
  onBack: () => void;
  onNext: () => void;
  onSkip: (name: string, imageSrc?: string) => void;
  warehouseId: string;
}

const FloorplanStep: FunctionComponent<IProps> = ({
  floorplanId,
  onBack,
  onNext,
  onSkip,
  warehouseId,
}: IProps) =>
  floorplanId === undefined ? (
    <CreateFloorplan
      onBack={onBack}
      onNext={onNext}
      warehouseId={warehouseId}
      onSkip={onSkip}
    />
  ) : (
    <EditFloorplan
      floorplanId={floorplanId}
      onBack={onBack}
      onNext={onNext}
      warehouseId={warehouseId}
      onSkip={onSkip}
    />
  );
FloorplanStep.defaultProps = {
  floorplanId: undefined,
};

export default FloorplanStep;
