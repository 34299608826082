import Grid from '@mui/material/Grid';
import React, { FunctionComponent } from 'react';

import Filters from '@app/common/Filters';
import Map from '@app/common/FullMap';
import { IFilter } from '@reducers/filter';
import { cellSize } from '../../../utils';
import Walkthrough from '../../../utils/Walkthrough';

interface IProps {
  filters: Record<string, IFilter>;
  rawMap: boolean;
}

const LiveDataTabContent: FunctionComponent<IProps> = ({
  filters,
  rawMap,
}: IProps) => {
  const filterKeys = Object.keys(filters).filter(
    (k) => k !== 'simulator' && k !== 'popup'
  );

  return (
    <>
      <Grid container spacing={2} flex={1}>
        {filterKeys.map((id, index) => (
          <Grid
            key={id}
            item
            flexDirection="column"
            xs={cellSize(filterKeys, index)}
            flex={1}
            style={{
              display: 'flex',
              height: '100%',
            }}
          >
            <Filters
              activeFilters={['assets', 'assetTags', 'zones']}
              filterId={id}
            />
            <Map
              actions={{ hover: {}, select: {} }}
              filterId={id}
              forceDisabledLayers={rawMap ? ['gmaps'] : undefined}
              showLiveData
              showRawLiveData={rawMap}
            />
          </Grid>
        ))}
      </Grid>
      <Walkthrough type="live" />
    </>
  );
};

export default LiveDataTabContent;
