import React, { Component } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DiagramContainer from './DiagramContainer';
import Composer from './Composer';

interface IProps {
  fetchDiagrams: () => void;
  language: string;
}

class RouterComp extends Component<IProps> {
  public componentDidMount() {
    const { fetchDiagrams } = this.props;

    fetchDiagrams();
  }

  public render() {
    const { language } = this.props;

    return (
      <DndProvider backend={HTML5Backend}>
        <Routes>
          <Route
            path="/diagram/:id/edit"
            element={<Composer />}
          />
          <Route
            path="/diagram/new"
            element={<Composer />}
          />
          <Route
            path="/"
            element={<DiagramContainer />}
          />
          <Route
            path="*"
            element={<Navigate to={`/${language}/dashboard/diagrams`} replace />}
          />
        </Routes>
      </DndProvider>
    );
  }
}

export default RouterComp;
