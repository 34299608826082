import Badge from '@mui/material/Badge';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import ListItemButton from '@mui/material/ListItemButton';
import CropFreeIcon from '@mui/icons-material/CropFree';
import HomeIcon from '@mui/icons-material/Home';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import CommentIcon from '@mui/icons-material/Comment';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { withRouter } from '@app/utils/withRouter';
import Account from '@models/Account';
import AccountMeta from '@models/AccountMeta';
import { ITab } from './Menu/Menu';

interface IProps {
  account?: Account;
  accounts: AccountMeta[];
  language: string;
  shortcuts: Record<string, boolean>;
  tabs: ITab[];
  activeChat: boolean;
  toggleChat: () => void; 

  router: any;
}

const scan = (language: string, router: any) => {
  // @ts-ignore
  if (window.MessageInvoker) {
    // @ts-ignore
    window.MessageInvoker.postMessage('scan');
  }

  const interval = setInterval(() => {
    // @ts-ignore
    const data = window.nativeData;

    if (data) {
      // @ts-ignore
      window.nativeData = undefined;

      clearInterval(interval);

      router.navigate(
        `/${language}/dashboard/assets/assetid/${data}/management/tab/0`
      );
    }
  }, 200);

  setTimeout(() => {
    if (interval) {
      clearInterval(interval);
    }
  }, 120000);
};

const ShortCutMenu: FunctionComponent<IProps> = ({
  account,
  accounts,
  router,
  language,
  tabs,
  shortcuts,
  activeChat,
  toggleChat,
}: IProps) => {
  let monitorURL = 'https://console.logidot.com';
  if (window.location.origin.indexOf('localhost') !== -1) {
    monitorURL = 'http://localhost:3001';
  } else if (window.location.origin.indexOf('dashboard.dev') !== -1) {
    monitorURL = 'https://console.dev.sonodot.com';
  }

  let unread = 0;
  accounts.forEach((a) => {
    unread += a.messagesUnseen;
  });

  return (
    <Paper className="shortcut-menu">
      <List>
        <Tooltip title="Home">
          <ListItem button component={Link} to={`/${language}/dashboard`}>
            <HomeIcon />
          </ListItem>
        </Tooltip>
        {
          // @ts-ignore
          window.MessageInvoker ? (
            <ListItem
              button
              style={{
                backgroundColor: 'rgba(63, 81, 181, 0.1)',
                marginBottom: '20px',
                paddingLeft: '16px',
              }}
              onClick={() => scan(language, router)}
            >
              <CropFreeIcon />
            </ListItem>
          ) : null
        }
        {tabs
          .filter((tab) => shortcuts[tab.id])
          .map((tab) => (
            <Tooltip key={tab.link} title={tab.title}>
              <ListItem
                button
                component={Link}
                to={tab.link}
                style={{ color: tab.shortcutColor }}
              >
                {tab.icon}
              </ListItem>
            </Tooltip>
          ))}
        {(account || ({} as Account)).admin ? (
          <Tooltip title="Console">
            <ListItem
              style={{ color: '#ff6633' }}
              button
              component="a"
              href={monitorURL}
            >
              <DesktopWindowsIcon />
            </ListItem>
          </Tooltip>
        ) : null}
        <ListItemButton onClick={toggleChat}>
          <Badge badgeContent={Number(unread || 0)} max={999} color="secondary">
            <CommentIcon style={{ opacity: activeChat ? 1 : 0.3 }} />
          </Badge>
        </ListItemButton>
      </List>
    </Paper>
  );
};
ShortCutMenu.defaultProps = {
  account: undefined,
};

export default withRouter(ShortCutMenu);
