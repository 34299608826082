import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import React, { FunctionComponent } from 'react';

import './index.css';

interface IProps extends ButtonProps {
  disabled?: boolean;
  id?: string;
  onClick?: () => void;
  loading: boolean;
  children: React.ReactNode;
  circularProgressColor?: CircularProgressProps['color'];
  fullWidth?: boolean;
}

const LoadingButton: FunctionComponent<IProps> = ({
  children,
  circularProgressColor = 'primary',
  color,
  disabled,
  id,
  loading,
  onClick = () => {},
  type = 'button',
  variant = 'text',
  fullWidth = false,
}: IProps) => (
  <div className="loading-button-wrapper">
    <Button
      color={color}
      disabled={disabled || loading}
      id={id}
      onClick={onClick}
      type={type}
      variant={variant}
      fullWidth={fullWidth}
    >
      {children}
    </Button>
    {loading && (
      <CircularProgress
        className="loading-button"
        color={circularProgressColor}
        size={24}
      />
    )}
  </div>
);
LoadingButton.defaultProps = {
  id: undefined,
  disabled: false,
  onClick: undefined,
  circularProgressColor: undefined,
  fullWidth: false,
};

export default LoadingButton;
