import Typography from '@mui/material/Typography';
import React, { FunctionComponent } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import Loading from '@app/common/Loading';

const messages = defineMessages({
  fileDownloaded: {
    defaultMessage: 'File downloaded.',
    id: 'dashboard.report.pdf.filedownloaded',
  },
});

interface IProps {
  loading: boolean;
  url?: string;
}

const PDF: FunctionComponent<IProps> = ({ loading, url }: IProps) => {
  const intl = useIntl();

  return (
    <Loading loading={loading}>
      {url !== undefined ? (
        <iframe
          id="report-pdf"
          style={{ width: '100%', height: '100%', minHeight: '500px' }}
          title="view"
          src={url}
        />
      ) : (
        <Typography variant="body1">
          {intl.formatMessage(messages.fileDownloaded)}
        </Typography>
      )}
    </Loading>
  );
};
PDF.defaultProps = {
  url: undefined,
};

export default PDF;
