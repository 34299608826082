import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import React, { FunctionComponent } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const Speed = styled('span')(() => ({
  position: 'absolute',
  right: '10px',
  top: '35px',
}));
const TS = styled('span')(() => ({
  color: '#666',
  fontSize: '10px',
  fontWeight: 'bold',
  left: '10px',
  position: 'absolute',
  top: '35px',
}));

const messages = defineMessages({
  backwards: {
    defaultMessage: 'Backwards',
    id: 'dashboard.events.controls.backwards',
  },
  next: {
    defaultMessage: 'Next',
    id: 'dashboard.events.controls.next',
  },
  play: {
    defaultMessage: 'Play/pause',
    id: 'dashboard.events.controls.play',
  },
  previous: {
    defaultMessage: 'Previous',
    id: 'dashboard.events.controls.previous',
  },
  slowDown: {
    defaultMessage: 'Slow Down',
    id: 'dashboard.events.controls.slowdown',
  },
  speedUp: {
    defaultMessage: 'Speed Up',
    id: 'dashboard.events.controls.speedup',
  },
});

interface IProps {
  direction: number;
  playing: boolean;
  speed: number;
  time?: string;

  fastRewind: () => void;
  backward: () => void;
  pause: () => void;
  start: () => void;
  forward: () => void;
  fastForward: () => void;
  backwards: () => void;
}

const PlayerControls: FunctionComponent<IProps> = ({
  direction,
  playing,
  speed,
  time,

  fastRewind,
  backward,
  pause,
  start,
  forward,
  fastForward,
  backwards,
}: IProps) => {
  const intl = useIntl();

  return (
    <CardActions
      id="controls"
      style={{ justifyContent: 'center', position: 'relative' }}
    >
      {time !== undefined ? <TS id="player-ts">{time}</TS> : null}
      <Speed>
        {speed !== 0 && speed !== 1 && (
          <small style={{ color: '#666' }}>
            {speed}
            <span>x</span>
          </small>
        )}
      </Speed>
      <Tooltip title={intl.formatMessage(messages.slowDown)}>
        <IconButton
          aria-label={intl.formatMessage(messages.slowDown)}
          id="slow-down"
          onClick={fastRewind}
        >
          <FastRewindIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={intl.formatMessage(messages.previous)}>
        <IconButton
          aria-label={intl.formatMessage(messages.previous)}
          id="previous"
          onClick={backward}
        >
          <SkipPreviousIcon />
        </IconButton>
      </Tooltip>
      {playing ? (
        <Tooltip title={intl.formatMessage(messages.play)}>
          <IconButton
            aria-label={intl.formatMessage(messages.play)}
            id="play"
            style={{
              color: direction === -1 ? 'red' : 'rgba(0, 0, 0, 0.54)',
            }}
            onClick={pause}
          >
            <PauseIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={intl.formatMessage(messages.play)}>
          <IconButton
            aria-label={intl.formatMessage(messages.play)}
            id="play"
            style={{
              color: direction === -1 ? 'red' : 'rgba(0, 0, 0, 0.54)',
            }}
            onClick={start}
          >
            <PlayArrowIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={intl.formatMessage(messages.next)}>
        <IconButton
          aria-label={intl.formatMessage(messages.next)}
          id="next"
          onClick={forward}
        >
          <SkipNextIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={intl.formatMessage(messages.speedUp)}>
        <IconButton
          aria-label={intl.formatMessage(messages.speedUp)}
          id="speed-up"
          onClick={fastForward}
        >
          <FastForwardIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={intl.formatMessage(messages.backwards)}>
        <IconButton
          aria-label={intl.formatMessage(messages.backwards)}
          id="backwards"
          style={{
            color: direction === -1 ? 'red' : 'rgba(0, 0, 0, 0.54)',
          }}
          onClick={backwards}
        >
          <ReplayIcon
            fontSize="small"
            style={{
              transform: direction === -1 ? 'rotate(180deg)' : 'none',
            }}
          />
        </IconButton>
      </Tooltip>
    </CardActions>
  );
};
PlayerControls.defaultProps = {
  time: undefined,
};

export default PlayerControls;
