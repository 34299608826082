import Grid from '@mui/material/Grid';
import React, { FunctionComponent } from 'react';

import Filters from '@app/common/Filters';
import DateTimeRangeSlider from '@app/common/DateTimeRangeSlider/WithHotDates';
import { IFilter } from '@reducers/filter';
import { cellSize } from '../../../utils';
import AlertsTabContainer from './AlertsTabContainer';

interface IProps {
  filters: Record<string, IFilter>;
}

const AlertsTabGrid: FunctionComponent<IProps> = ({ filters }: IProps) => {
  const filterKeys = Object.keys(filters).filter(
    (k) => k !== 'simulator' && k !== 'popup'
  );

  return (
    <Grid container spacing={2} style={{ flex: 1 }}>
      {filterKeys.map((filterId, index) => (
        <Grid
          key={filterId}
          container
          item
          xs={cellSize(filterKeys, index)}
          direction="column"
          flex={1}
          style={{
            display: 'flex',
            minHeight: '100%',
          }}
        >
          <Filters
            activeFilters={['rules', 'ruleTemplates']}
            filterId={filterId}
          >
            <DateTimeRangeSlider filterId={filterId} />
            <AlertsTabContainer filterId={filterId} />
          </Filters>
        </Grid>
      ))}
    </Grid>
  );
};

export default AlertsTabGrid;
