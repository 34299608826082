import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AddIcon from '@mui/icons-material/Add';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import {
  IDeleteFloorplan,
  IDeleteWarehouse,
  IUpdateFloorplan,
} from '@actions/index';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import Warehouse, { Warehouses } from '@models/Warehouse';
import { WarehouseWithFloorplans } from '@models/WarehouseWithFloorplans';
import { IConfirmDialog } from '@app/dialogs/ConfirmDialog/types';
import { getWarehouseWithFloorplans } from '@selectors/warehouses';
import WarehouseFormDialog from '../../../forms/WarehouseForm/index';
import AccountsSettings from './Accounts';
import ConfigurationFiles from './ConfigurationFiles';
import OtherSettingsItem from './OtherSettings';
import PreCheckLists from './PreCheckLists';
import WarehousesItem from './WarehousesItem';

interface IProps {
  confirm: (properties: IConfirmDialog) => void;
  deleteFloorplan: (properties: IDeleteFloorplan) => void;
  deleteWarehouse: (properties: IDeleteWarehouse) => void;
  language: string;
  loading: boolean;
  resetWarehouses: (warehouses: Warehouses, intl: IntlShape) => void;
  updateFloorplan: (properties: IUpdateFloorplan) => void;
  floorplans: IncompleteFloorplans;
  warehouses: Warehouses;

  router: any;

  intl: IntlShape;
}

interface IState {
  activeWarehouseId?: string;
  activeItem?: string;
  editionWarehouseData?: WarehouseWithFloorplans;
  open?: boolean;
  openWarehouseForm?: boolean;
}

class SettingsTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { router } = props;

    this.state = {
      activeItem: router.params.setting,
      activeWarehouseId: router.params.warehouseId,
      open: router.params.setting === 'warehouses',
    };

    this.handleAddWarehouse = this.handleAddWarehouse.bind(this);
    this.handleEditWarehouse = this.handleEditWarehouse.bind(this);
    this.handleWarehouseFormClose = this.handleWarehouseFormClose.bind(this);
    this.removeWhSelection = this.removeWhSelection.bind(this);
    this.handleWHListClick = this.handleWHListClick.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { router } = this.props;

    if (
      router.params.setting &&
      (router.params.setting !== prevProps.router.params.setting ||
        router.params.warehouseId !== prevProps.router.params.warehouseId)
    ) {
      this.setActiveItem(router.params);
    }
  }

  public handleAddWarehouse() {
    this.setState({
      editionWarehouseData: undefined,
      openWarehouseForm: true,
    });
  }

  public handleEditWarehouse() {
    const { floorplans, warehouses } = this.props;
    const { activeWarehouseId } = this.state;

    this.setState({
      editionWarehouseData: getWarehouseWithFloorplans(
        warehouses,
        floorplans,
        activeWarehouseId || ''
      ),
      openWarehouseForm: true,
    });
  }

  public handleWarehouseFormClose() {
    this.setState({
      editionWarehouseData: undefined,
      openWarehouseForm: undefined,
    });
  }

  public handleWHListClick() {
    const { router, language } = this.props;
    const { open } = this.state;

    this.setState({ open: !open }, () =>
      router.navigate(`/${language}/dashboard/settings/warehouses`)
    );
  }

  public handleListClick(item: string) {
    const { router, language } = this.props;

    router.navigate(`/${language}/dashboard/settings/${item}`);
  }

  public handleWHClick(warehouse: Warehouse) {
    const { router, language } = this.props;

    router.navigate(`/${language}/dashboard/settings/warehouses/${warehouse.id}`);
  }

  public setActiveItem(params: any) {
    this.setState({
      activeItem: params.setting,
      activeWarehouseId: params.warehouseId,
    });
  }

  public getSelectedItem(activeItem: any) {
    const { activeWarehouseId } = this.state;

    switch (activeItem) {
      case 'accounts':
        return <AccountsSettings />;
      case 'other_settings':
        return <OtherSettingsItem {...this.props} />;
      case 'configurationfiles':
        return <ConfigurationFiles {...this.props} />;
      case 'warehouses':
        return (
          <WarehousesItem
            {...this.props}
            handleEditWarehouse={this.handleEditWarehouse}
            removeWhSelection={this.removeWhSelection}
            warehouseId={activeWarehouseId || ''}
          />
        );
      case 'prechecklists':
        return <PreCheckLists {...this.props} />;
      default:
        return null;
    }
  }

  public removeWhSelection() {
    this.setState({ activeWarehouseId: undefined });
  }

  public render() {
    const { warehouses } = this.props;
    const {
      activeWarehouseId,
      activeItem,
      editionWarehouseData,
      openWarehouseForm,
      open,
    } = this.state;

    return (
      <>
        <CardHeader
          action={
            activeItem === 'warehouses' ? (
              <Button onClick={this.handleAddWarehouse}>
                <AddIcon style={{ marginRight: '5px' }} />
                <FormattedMessage
                  id="dashboard.settings.addwarehouse"
                  defaultMessage="Add Warehouse"
                />
              </Button>
            ) : null
          }
          title={
            <FormattedMessage
              id="dashboard.settings.title"
              defaultMessage="Settings"
            />
          }
        />
        <Divider style={{ marginBottom: '20px' }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={4}>
            <Paper>
              <List component="nav">
                <ListItem
                  button
                  onClick={() => this.handleListClick('accounts')}
                  selected={activeItem === 'accounts'}
                >
                  <ListItemIcon>
                    <AccountBoxIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.settings.menu_accounts"
                        defaultMessage="Accounts"
                      />
                    }
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={this.handleWHListClick}
                  selected={activeItem === 'warehouses'}
                >
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.settings.menu_warehouses"
                        defaultMessage="Warehouses"
                      />
                    }
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      flexGrow: 1,
                    }}
                  >
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </div>
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="data" disablePadding>
                    {Object.values(warehouses).map((w) => (
                      <ListItem
                        key={w.id}
                        button
                        onClick={() => this.handleWHClick(w)}
                        selected={
                          activeItem === 'warehouses' &&
                          w.id === activeWarehouseId
                        }
                      >
                        <ListItemIcon>
                          <ChevronRightIcon style={{ color: '#E1E1E1' }} />
                        </ListItemIcon>
                        <ListItemText primary={w.name} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
                <ListItem
                  button
                  onClick={() => this.handleListClick('configurationfiles')}
                  selected={activeItem === 'configurationfiles'}
                >
                  <ListItemIcon>
                    <GraphicEqIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.settings.menu_configurationfiles"
                        defaultMessage="Configuration files"
                      />
                    }
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => this.handleListClick('prechecklists')}
                  selected={activeItem === 'prechecklists'}
                >
                  <ListItemIcon>
                    <CheckBoxIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.settings.menu_prechecklists"
                        defaultMessage="Pre-Check Lists"
                      />
                    }
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => this.handleListClick('other_settings')}
                  selected={activeItem === 'other_settings'}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.settings.menu_othersettings"
                        defaultMessage="App Settings"
                      />
                    }
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9} lg={8}>
            {this.getSelectedItem(activeItem)}
          </Grid>
        </Grid>
        {openWarehouseForm ? (
          <WarehouseFormDialog
            onClose={this.handleWarehouseFormClose}
            data={editionWarehouseData}
          />
        ) : null}
      </>
    );
  }
}

export default injectIntl(SettingsTab);
