import React, { FunctionComponent } from 'react';

import './index.css';

interface IProps {
  children?: any;
  loading: boolean;
  style?: object;
  theme: string;
}

const Loading: FunctionComponent<IProps> = ({
  loading,
  children,
  style,
  theme,
}: IProps) =>
  loading ? (
    <div className="loading-hoc" style={{ ...(style || {}) }}>
      <img
        alt="..."
        src={
          theme === 'dark' ? '/assets/loading_dark.gif' : '/assets/loading.gif'
        }
        style={{ maxWidth: '48px' }}
      />
    </div>
  ) : (
    <>{children}</>
  );

Loading.defaultProps = {
  children: undefined,
  style: undefined,
};

export default Loading;
