import React, { FunctionComponent, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Loading from '@app/common/Loading';
const ShiftForm = lazy(() => import('./ShiftForm'));
const Tab = lazy(() => import('./Tab'));

interface IProps {
  language: string;
}

const RouterComp: FunctionComponent<IProps> = ({ language }: IProps) => (
  <Suspense fallback={<Loading loading />}>
    <Routes>
      <Route
        path="/:id/edit"
        element={<ShiftForm />}
      />
      <Route
        path="/new"
        element={<ShiftForm />}
      />
      <Route
        path="/"
        element={<Tab />}
      />
      <Route
        path="*"
        element={<Navigate to={`/${language}/dashboard/shifts`} replace />}
      />
    </Routes>
  </Suspense>
);

export default RouterComp;
