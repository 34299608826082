import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { toggleMenu } from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import { getAccounts } from '@selectors/accounts';

import Menu from './Menu';

import './index.css';

const mapStateToProps = (state: IAppState) => ({
  accounts: Object.values(getAccounts(state.accounts)),
  account: state.authentication.account,
  language: getActiveLanguage(state),
  open: state.app.menu,
  theme: state.app.theme,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleMenu: (open: boolean) => dispatch(toggleMenu(open)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Menu) as any
);
