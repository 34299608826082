import Grid from '@mui/material/Grid';
import React, { FunctionComponent } from 'react';

import Filters from '@app/common/Filters';
import { IFilter } from '@reducers/filter';
import AssetsTabContainer from './AssetsTabContainer';
import { cellSize } from '../../../utils';
import FormInjector from './FormInjector';

interface IProps {
  filters: Record<string, IFilter>;
}

const SimulationsTabGrid: FunctionComponent<IProps> = ({ filters }: IProps) => {
  const filterKeys = Object.keys(filters).filter(
    (k) => k !== 'simulator' && k !== 'popup'
  );

  return (
    <>
      <Grid container spacing={2}>
        {filterKeys.map((filterId, index) => (
          <Grid key={filterId} item xs={cellSize(filterKeys, index)}>
            <Filters filterId={filterId}>
              <AssetsTabContainer
                filterId={filterId}
                extendGrid={index % 2 === 0 && index === filterKeys.length - 1}
              />
            </Filters>
          </Grid>
        ))}
      </Grid>
      <FormInjector />
    </>
  );
};

export default SimulationsTabGrid;
