import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React, { Component } from 'react';

import Filters from '@app/common/Filters';
import Map from '@app/common/FullMap';
import { IFilter } from '@reducers/filter';
import { ISimulatorConfig } from '@models/Simulation';
import AssetSelector from './AssetSelector';
import { Assets } from '@models/Asset';
import { OrderPosition } from '@app/common/FullMap/Map/Layers/SimulatorRouting/SimulatorRouting';

interface IProps {
  assets: Assets;
  filters: Record<string, IFilter>;
  filterId: string;
  fetchRoutingData: (floorplanId: string) => void;
  data: ISimulatorConfig;
}

interface IState {
  assetId?: string;
  openSelector: boolean;
  orders: OrderPosition[]
}

class OperatorTab extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      openSelector: false,
      orders: [],
    };

    this.selectAsset = this.selectAsset.bind(this);
    this.openAssetSelector = this.openAssetSelector.bind(this);
    this.closeAssetSelector = this.closeAssetSelector.bind(this);
    this.setOrders = this.setOrders.bind(this);
  }

  public componentDidMount() {
    const { fetchRoutingData, filters, filterId } = this.props;

    fetchRoutingData(filters[filterId].floorplanId || '');

    window.addEventListener('routing_orders', this.setOrders);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('routing_orders', this.setOrders);
  }

  public setOrders(e: any) {
    this.setState({ orders: e.detail })
  }

  public selectAsset(event: any) {
    this.setState({ assetId: event.target.value });
  }

  public openAssetSelector() {
    this.setState({ openSelector: true });
  }

  public closeAssetSelector() {
    this.setState({ openSelector: false });
  }

  public render() {
    const { assets, data, filterId } = this.props;
    const { assetId, openSelector, orders } = this.state;

    return (
      <>
        <Grid container spacing={2} flex={1}>
          <Grid
            key={filterId}
            item
            flexDirection="column"
            xs={9}
            flex={1}
            style={{
              display: 'flex',
              height: '100%',
            }}
          >
            <Filters
              activeFilters={[]}
              filterId={filterId}
            />
            <Map
              filterId={filterId}
              layers={{
                simulator: {
                  id: 'simulator',
                  label: 'Sim',
                  disabled: true,
                  active: true,
                  data: { ...data },
                },
                simulatorRouting: {
                  id: 'simulatorRouting',
                  label: 'Sim',
                  disabled: true,
                  active: true,
                  data: { ...data, myAssetId: assetId },
                },
              }}
              forceDisabledLayers={['gmaps']}
              actions={{ hover: {}, select: {} }}
              showLiveData
              showRawLiveData={false}
            />
          </Grid>
          <Grid item xs={3}>
            <Box mt={6} mr={2}>
              <Button fullWidth color="primary" variant="contained" onClick={this.openAssetSelector}>
                <span>Change Asset</span>
                {assets[assetId || ''] ? <span> ({(assets[assetId || ''] || {}).name})</span> : null}
              </Button>
            </Box>
            <Box mt={2} mr={2}>
              <Box p={2}>
                <h4>Next Picks</h4>
              </Box>

              {(orders).map((_, i) => (<Box p={2}>Order #{i + 1}</Box>))}
            </Box>
          </Grid>
        </Grid>
        {openSelector || !assetId ? (
          <AssetSelector
            assets={assets}
            selectAsset={this.selectAsset}
            assetId={assetId}
            closeAssetSelector={this.closeAssetSelector}
          />
        ) : null}
      </>
    );
  }
}

export default OperatorTab;
