import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import React, { Component } from 'react';

import { MapWarning } from '@actions/index';
import { IFilterEntry } from '@reducers/filter';
import MapLoading from './MapLoading';
import Map from './Map';
import { ILayer, MapActions, MapMetricData } from './types';

import './MapGrid.css';

export interface IProps {
  forceActiveLayers?: string[];
  forceDisabledLayers?: string[];
  metricData?: MapMetricData;
  showLiveData?: boolean;
  showRawLiveData?: boolean;

  actions?: MapActions;

  filters: Record<string, IFilterEntry>;
  filterId?: string;

  help?: React.ReactElement | HTMLElement | string;

  ts?: number;

  layers?: Record<string, ILayer>;

  warnings?: Record<string, MapWarning>;
}

interface IState {
  stateLayers: Record<string, ILayer>;
}

class MapGrid extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { layers } = props;

    this.state = {
      stateLayers: { ...layers },
    };
  }

  public componentDidUpdate(prevProps: any) {
    const { filters, layers } = this.props;

    if (Object.keys(filters).length < Object.keys(prevProps.filters).length) {
      window.dispatchEvent(new Event('resize'));
    }

    if (JSON.stringify(prevProps.layers) !== JSON.stringify(layers)) {
      this.setState({ stateLayers: { ...layers } });
    }
  }

  public render() {
    const {
      actions,
      filterId,
      filters,
      forceActiveLayers,
      forceDisabledLayers,
      metricData,
      showLiveData,
      showRawLiveData,
      warnings,
    } = this.props;
    const { stateLayers } = this.state;
    const parsedFilters: Record<string, IFilterEntry> = filters;

    const filterKeys = Object.keys(parsedFilters).filter(
      (f) => (f !== 'simulator' && f !== 'popup' && !filterId) || f === filterId
    );

    return (
      <Card className="map-wrapper">
        <Grid container>
          {filterKeys
            .filter(
              (key) => (key !== 'simulator' && key !== 'popup') || filterId
            )
            .map((key, index) => {
              let size: 6 | 12 = filterKeys.length > 1 ? 6 : 12;
              if (
                size === 6 &&
                index === filterKeys.length - 1 &&
                (index + 1) % 2 !== 0
              ) {
                size = 12;
              }

              const map = parsedFilters[key];

              return (
                <Grid
                  key={key}
                  style={{
                    borderLeft:
                      index % 2 !== 0 ? '1px solid #E1E1E1' : undefined,
                  }}
                  flex={1}
                  container
                  item
                  md={size}
                >
                  <div className="map-filter-container">
                    {map.floorplanId ? (
                      <MapLoading floorplanId={map.floorplanId}>
                        <Map
                          actions={actions}
                          warnings={warnings}
                          filterId={key}
                          forceActiveLayers={forceActiveLayers}
                          forceDisabledLayers={forceDisabledLayers}
                          metricData={metricData}
                          floorplanId={map.floorplanId}
                          externalLayers={JSON.parse(
                            JSON.stringify(stateLayers)
                          )}
                          showLiveData={showLiveData}
                          showRawLiveData={showRawLiveData}
                        />
                      </MapLoading>
                    ) : null}
                  </div>
                </Grid>
              );
            })}
        </Grid>
      </Card>
    );
  }
}

export default MapGrid;
