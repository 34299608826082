import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Loading from '@app/common/Loading';
import { Date, timeZone } from '@dashboard_utils/index';
import { IncompleteFloorplans } from '@models/IncompleteFloorplan';
import SensorGroup, { SensorGroups } from '@models/SensorGroup';
import { Warehouses } from '@models/Warehouse';
import { IState } from '../types';

interface IProps {
  allowEdition: boolean;
  id?: string;
  content: string;
  filename: string;
  floorplanId?: string;
  sensorGroupId?: string;
  warehouseId?: string;

  floorplans: IncompleteFloorplans;
  warehouses: Warehouses;
  sensorGroups: SensorGroups;

  loading: boolean;

  handleChange: (key: keyof IState) => any;
}

const messages = defineMessages({
  contentHelperText: {
    defaultMessage: 'Required',
    id: 'dashboard.forms.configfileform.field_content_helper',
  },
  contentPlaceholder: {
    defaultMessage: 'Content',
    id: 'dashboard.forms.configfileform.label_content',
  },
});

const ConfigFileFormContent: FunctionComponent<IProps> = ({
  allowEdition,

  id,
  content,
  filename,
  floorplanId,
  sensorGroupId,
  warehouseId,

  floorplans,
  warehouses,
  sensorGroups,

  loading,

  handleChange,
}: IProps) => {
  const intl = useIntl();

  const warehouseTz = warehouses[warehouseId || '']
    ? (warehouses[warehouseId || ''] || {}).timezone
    : timeZone;

  return (
    <Grid container>
      <Grid item md={4} xs={12}>
        <div style={{ padding: '0px 40px' }}>
          <FormControl required fullWidth margin="normal">
            <InputLabel htmlFor="form-warehouseid">
              <FormattedMessage
                id="dashboard.forms.configfileform.label_warehouse"
                defaultMessage="Warehouse"
              />
            </InputLabel>
            <Select
              disabled={allowEdition === false || !!id}
              displayEmpty
              input={<Input name="warehouseid" id="form-warehouseid" />}
              onChange={handleChange('warehouseId')}
              value={warehouseId || ''}
            >
              <MenuItem />
              {Object.values(warehouses).map((w) => (
                <MenuItem key={w.id} value={w.id}>
                  {w.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl required fullWidth margin="normal">
            <InputLabel htmlFor="form-floorplanid">
              <FormattedMessage
                id="dashboard.forms.configfileform.label_floorplan"
                defaultMessage="Floor plan"
              />
            </InputLabel>
            <Select
              disabled={allowEdition === false || !!id}
              displayEmpty
              input={<Input name="floorplanid" id="form-floorplanid" />}
              onChange={handleChange('floorplanId')}
              value={floorplanId || ''}
            >
              <MenuItem />
              {Object.values(floorplans)
                .filter((fp) => fp.warehouseId === warehouseId)
                .map((fp) => (
                  <MenuItem key={fp.id} value={fp.id}>
                    {fp.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Loading loading={loading}>
            <FormControl required fullWidth margin="normal">
              <InputLabel htmlFor="form-sensorgroupid">
                <FormattedMessage
                  id="dashboard.forms.configfileform.label_sensorgroup"
                  defaultMessage="Floor plan"
                />
              </InputLabel>
              <Select
                disabled={allowEdition === false || !!id}
                displayEmpty
                input={<Input name="sensorgroupid" id="form-sensorgroupid" />}
                onChange={handleChange('sensorGroupId')}
                value={sensorGroupId || ''}
              >
                <MenuItem />
                {sensorGroups &&
                  sensorGroups
                    .sort(
                      (a, b) =>
                        new Date(timeZone, b.created).getTime() -
                        new Date(timeZone, a.created).getTime()
                    )
                    .map((sg: SensorGroup) => (
                      <MenuItem key={sg.id} value={sg.id}>
                        {sg.id}
                        <span> </span>
                        <small style={{ paddingLeft: '10px', color: '#CCC' }}>
                          {format(
                            new Date(warehouseTz, sg.created),
                            'yyyy-MM-dd'
                          )}
                        </small>
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Loading>
        </div>
      </Grid>
      <Grid item md={8} xs={12}>
        <FormControl required fullWidth margin="normal">
          <InputLabel required htmlFor="form-filename">
            <FormattedMessage
              id="dashboard.forms.configfileform.label_filename"
              defaultMessage="File name"
            />
          </InputLabel>
          <Input
            disabled={allowEdition === false}
            id="form-filename"
            value={filename}
            onChange={handleChange('filename')}
          />
          <FormHelperText>
            <FormattedMessage
              id="dashboard.forms.configfileform.field_filename_helper"
              defaultMessage="Required"
            />
          </FormHelperText>
        </FormControl>
        <TextField
          disabled={allowEdition === false}
          id="form-content"
          value={content}
          onChange={handleChange('content')}
          multiline
          rows={30}
          placeholder={intl.formatMessage(messages.contentPlaceholder)}
          helperText={intl.formatMessage(messages.contentHelperText)}
          fullWidth
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};
ConfigFileFormContent.defaultProps = {
  id: undefined,
  floorplanId: undefined,
  sensorGroupId: undefined,
  warehouseId: undefined,
};

export default ConfigFileFormContent;
