import React, { FunctionComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ImportItemsForm from '../../../forms/ImportItemsForm';
import ImportStocksForm from '../../../forms/ImportStocksForm';
import ItemList from './ItemList';
import Locations from './Locations';

interface IProps {
  importItemsFormOpen: boolean;
  importStocksFormOpen: boolean;
  language: string;
}

const ItemsTab: FunctionComponent<IProps> = ({
  importItemsFormOpen,
  importStocksFormOpen,
  language,
}: IProps) => (
  <>
    <Routes>
      <Route path="/" element={<ItemList />} />
      <Route
        path="/locations"
        element={<Locations />}
      />
      <Route
        path="*"
        element={<Navigate to={`/${language}/dashboard/items`} replace />}
      />
    </Routes>

    {importItemsFormOpen ? <ImportItemsForm /> : undefined}
    {importStocksFormOpen ? <ImportStocksForm /> : undefined}
  </>
);

export default ItemsTab;
