import React, { FunctionComponent } from 'react';

import './index.css';

interface IProps {
  children?: any;
  loading: boolean;
  theme: string;
}

const FloatLoading: FunctionComponent<IProps> = ({
  children,
  loading,
  theme,
}: IProps) => (
  <>
    {children}
    {loading ? (
      <div className="floating-loading-hoc">
        <img
          src={
            theme === 'dark'
              ? '/assets/loading_dark.gif'
              : '/assets/loading.gif'
          }
          alt="..."
          style={{ maxWidth: '48px' }}
        />
      </div>
    ) : null}
  </>
);
FloatLoading.defaultProps = {
  children: undefined,
};

export default FloatLoading;
