import React, { FunctionComponent, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Loading from '@app/common/Loading';
import SimulationsTabGridContainer from './SimulationsTabGridContainer';

const SimulationAnalyticsPage = lazy(() => import('./Simulation/Analytics'));
const SimulationPlayerPage = lazy(() => import('./Simulation/Player'));
const SimulationForm = lazy(() => import('./SimulationForm'));

interface IProps {
  language: string;
}

const RouterComp: FunctionComponent<IProps> = ({ language }: IProps) => (
  <Suspense fallback={<Loading loading />}>
    <Routes>
      <Route
        path="/:id/analytics/:tab?"
        element={<SimulationAnalyticsPage />}
      />
      <Route
        path="/:id/analytics"
        element={<SimulationAnalyticsPage />}
      />
      <Route
        path="/:id/player"
        element={<SimulationPlayerPage />}
      />
      <Route
        path="/:floorplanId/new"
        element={<SimulationForm />}
      />
      <Route
        path="/"
        element={<SimulationsTabGridContainer />}
      />
      <Route
        path="*"
        element={<Navigate to={`/${language}/dashboard/simulations`} replace />}
      />
    </Routes>
  </Suspense>
);

export default RouterComp;
