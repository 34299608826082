import Grid from '@mui/material/Grid';
import React, { FunctionComponent } from 'react';

import { IFilter } from '@reducers/filter';
import ReportsTabContainer from './ReportsTabContainer';
import { cellSize } from '../../../utils';
import Walkthrough from '../../../utils/Walkthrough';

interface IProps {
  filters: Record<string, IFilter>;
}

const ReportsTabGrid: FunctionComponent<IProps> = ({ filters }: IProps) => {
  const filterKeys = Object.keys(filters).filter(
    (k) => k !== 'simulator' && k !== 'popup'
  );

  return (
    <>
      <Grid container spacing={2}>
        {filterKeys.map((filterId, index) => (
          <Grid key={filterId} item xs={cellSize(filterKeys, index)}>
            <ReportsTabContainer filterId={filterId} />
          </Grid>
        ))}
      </Grid>
      <Walkthrough type="reports" />
    </>
  );
};

export default ReportsTabGrid;
